import { ref, watch, onScopeDispose, computed } from "vue";
import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import type { IQuoteProductTableItem } from "@/interfaces/quotes.interface";

function useLocalStorage<T>(key: string, defaultValue?: T) {
  const val = ref(defaultValue);

  const storageVal = window.localStorage.getItem(key);

  if (storageVal) {
    val.value = JSON.parse(storageVal);
  }

  function handleStorageEvent(event: StorageEvent) {
    if (event.key === key) {
      val.value = JSON.parse(event.newValue || []);
    }
  }

  window.addEventListener("storage", handleStorageEvent);

  onScopeDispose(() => {
    window.removeEventListener("storage", handleStorageEvent);
  });

  watch(
    val,
    (newValue) => window.localStorage.setItem(key, JSON.stringify(newValue)),
    { deep: true }
  );

  return val;
}

export const usePofStore = defineStore("pof", () => {
  const pofProducts =
    useLocalStorage<Array<IQuoteProductTableItem>>("pof_products");

  function setPofProducts(value: any) {
    pofProducts.value = value;
  }

  function resetPofProducts() {
    pofProducts.value = [];
    window.localStorage.removeItem("pof_products");
  }

  const currentPofProducts = computed(() => {
    return pofProducts.value;
  });

  async function getPurchaseOrderForms(params: string) {
    try {
      const response = await ApiService.get(
        `/api/quote/purchase-order-form/${params}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getPurchaseOrderForm(pof_pk) {
    try {
      const response = await ApiService.get(
        `/api/quote/purchase-order-form/${pof_pk}/`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getPurchaseOrderProducts(pof_pk) {
    try {
      const response = await ApiService.get(
        `/api/quote/purchase-order-form/${pof_pk}/product/`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createPurchaseOrderForm(data) {
    try {
      const response = await ApiService.post(
        `/api/quote/purchase-order-form/`,
        data
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createPurchaseOrderProduct({ pof_pk, data }) {
    try {
      const response = await ApiService.post(
        `/api/quote/purchase-order-form/${pof_pk}/product/`,
        data
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function editPurchaseOrderForm({ pof_pk, data }) {
    try {
      const response = await ApiService.patch(
        `/api/quote/purchase-order-form/${pof_pk}/`,
        data
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function editPurchaseOrderProduct({ pof_pk, product_pk, data }) {
    try {
      const response = await ApiService.patch(
        `/api/quote/purchase-order-form/${pof_pk}/product/${product_pk}/`,
        data
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function deletePurchaseOrderProduct({ pof_pk, product_pk }) {
    try {
      const response = await ApiService.delete(
        `/api/quote/purchase-order-form/${pof_pk}/product/${product_pk}/`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createPofProductCustomerQuantity({
    pof_pk,
    product_pk,
    data,
  }) {
    try {
      const response = await ApiService.post(
        `/api/quote/purchase-order-form/${pof_pk}/product/${product_pk}/customer-quantities/`,
        data
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function editPofProductCustomerQuantity({
    pof_pk,
    product_pk,
    quantity_pk,
    data,
  }) {
    try {
      const response = await ApiService.patch(
        `/api/quote/purchase-order-form/${pof_pk}/product/${product_pk}/customer-quantities/${quantity_pk}/`,
        data
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function deletePofProductCustomerQuantity({
    pof_pk,
    product_pk,
    quantity_pk,
  }) {
    try {
      const response = await ApiService.delete(
        `/api/quote/purchase-order-form/${pof_pk}/product/${product_pk}/customer-quantities/${quantity_pk}/`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createPofProductSupplierQuantity({
    pof_pk,
    product_pk,
    data,
  }) {
    try {
      const response = await ApiService.post(
        `/api/quote/purchase-order-form/${pof_pk}/product/${product_pk}/supplier-quantities/`,
        data
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function editPofProductSupplierQuantity({
    pof_pk,
    product_pk,
    quantity_pk,
    data,
  }) {
    try {
      const response = await ApiService.patch(
        `/api/quote/purchase-order-form/${pof_pk}/product/${product_pk}/supplier-quantities/${quantity_pk}/`,
        data
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function deletePofProductSupplierQuantity({
    pof_pk,
    product_pk,
    quantity_pk,
  }) {
    try {
      const response = await ApiService.delete(
        `/api/quote/purchase-order-form/${pof_pk}/product/${product_pk}/supplier-quantities/${quantity_pk}/`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getPurchaseOrderDelivery(params: string) {
    try {
      const response = await ApiService.get(
        `/api/quote/purchase-order-delivery/${params}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getPurchaseOrderStatus(params: string) {
    try {
      const response = await ApiService.get(
        `/api/quote/purchase-order-status/${params}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getPurchaseOrderAttachments(pof_pk) {
    try {
      const response = await ApiService.get(
        `/api/quote/purchase-order-form/${pof_pk}/attachments/`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createPurchaseOrderAttachment({ pof_pk, form_data }) {
    try {
      const response = await ApiService.post(
        `/api/quote/purchase-order-form/${pof_pk}/attachments/`,
        form_data
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function deletePurchaseOrderAttachment({ pof_pk, attachment_pk }) {
    try {
      const response = await ApiService.delete(
        `/api/quote/purchase-order-form/${pof_pk}/attachments/${attachment_pk}/`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function approvePurchaseOrderForm(pof_pk) {
    try {
      const response = await ApiService.get(
        `/api/quote/purchase-order-form/${pof_pk}/approve-form/`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function cancelPurchaseOrderForm(pof_pk) {
    try {
      const response = await ApiService.get(
        `/api/quote/purchase-order-form/${pof_pk}/cancel-form/`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    setPofProducts,
    resetPofProducts,
    currentPofProducts,
    getPurchaseOrderForms,
    getPurchaseOrderForm,
    getPurchaseOrderProducts,
    createPurchaseOrderForm,
    editPurchaseOrderForm,
    createPurchaseOrderProduct,
    editPurchaseOrderProduct,
    deletePurchaseOrderProduct,
    createPofProductCustomerQuantity,
    editPofProductCustomerQuantity,
    deletePofProductCustomerQuantity,
    createPofProductSupplierQuantity,
    editPofProductSupplierQuantity,
    deletePofProductSupplierQuantity,
    getPurchaseOrderDelivery,
    getPurchaseOrderAttachments,
    createPurchaseOrderAttachment,
    deletePurchaseOrderAttachment,
    approvePurchaseOrderForm,
    cancelPurchaseOrderForm,
    getPurchaseOrderStatus,
  };
});

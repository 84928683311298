import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useConfigStore } from "@/stores/config";
import { useUtilityStore } from "@/stores/utility";
import { usePofStore } from "@/stores/pof";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import QuoteFilterService from "@/core/services/QuoteFilterService";
import ActivityFilterService from "@/core/services/ActivityFilterService";
import ProjectFilterService from "@/core/services/ProjectFilterService";
import PurchaseOrderFormService from "@/core/services/PurchaseOrderFormService";
import SpecialPricesFilterService from "@/core/services/SpecialPricesFilterService";

const userRoles = [
  "ROLE_ACCOUNT_MANAGER",
  "ROLE_FAE",
  "ROLE_FINANCE_USER_RSP_SAMPLE",
  "ROLE_SB_STORAGE_USER",
  "ROLE_ELK_STORAGE_USER",
  "ROLE_ANNOUNCE_RESIGNATION",
  "ROLE_SECRETARY",
  "ROLE_TECHNOLOGY",
  "ROLE_HR_USER",
  "ROLE_FINANCE_USER",
  "ROLE_READ_ONLY",
  "ROLE_TOP_MANAGER",
  "ROLE_IT_USER",
  "ROLE_CALENDAR_ADMIN",
  "ROLE_CUSTOMER_REPRESENTATIVE",
  "ROLE_SHIPMENT_USER",
  "ROLE_LINE_MANAGER",
  "ROLE_MANAGER",
  "ROLE_SUPER",
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/",
        name: "mainpage",
        meta: {
          pageTitle: "Mainpage",
          breadcrumbs: ["Mainpage"],
          requiresAuth: true,
          permissions: userRoles,
        },
        component: () => import("@/views/mainpage/index.vue"),
      },
      {
        path: "/dashboards/:id",
        name: "dashboard",
        component: () => import("@/views/dashboard/index.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
          ],
        },
      },
      {
        path: "/dictionary",
        name: "dictionary",
        component: () => import("@/views/dictionary/index.vue"),
        meta: {
          pageTitle: "Dictionary",
          breadcrumbs: ["Dictionary"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/storage",
        name: "storage-view",
        component: () => import("@/views/storage/index.vue"),
        meta: {
          pageTitle: "Storage",
          breadcrumbs: ["Storage"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/expenses",
        name: "expenses",
        component: () => import("@/views/expense/index.vue"),
        meta: {
          pageTitle: "Expenses",
          breadcrumbs: ["Expenses"],
          requiresAuth: true,
          permissions: [
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_FAE",
            "ROLE_FINANCE_USER_RSP_SAMPLE",
            "ROLE_FINANCE_USER",
            "ROLE_READ_ONLY",
            "ROLE_CUSTOMER_REPRESENTATIVE",
            "ROLE_SUPER",
            "ROLE_LINE_MANAGER",
            "ROLE_MANAGER",
          ],
        },
      },
      {
        path: "/expenses/add",
        name: "expenses-add",
        component: () => import("@/views/expense/add/index.vue"),
        meta: {
          pageTitle: "Add Expense",
          breadcrumbs: ["Add Expense"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/expenses/edit/:id",
        name: "expenses-edit",
        component: () => import("@/views/expense/edit/index.vue"),
        meta: {
          pageTitle: "Edit Expense",
          breadcrumbs: ["Edit Expense"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/expenses/view/:id",
        name: "expenses-view",
        component: () => import("@/views/expense/view/index.vue"),
        meta: {
          pageTitle: "View Expense",
          breadcrumbs: ["View Expense"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/activities",
        name: "activities",
        component: () => import("@/views/activities/index.vue"),
        meta: {
          pageTitle: "Activities",
          breadcrumbs: ["Activities"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
          ],
        },
      },
      {
        path: "/activities/add",
        name: "activities-add",
        component: () => import("@/views/activities/add/index.vue"),
        meta: {
          pageTitle: "Add Activity",
          breadcrumbs: ["Add Activity"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
          ],
        },
      },
      {
        path: "/activities/edit/:id",
        name: "activities-edit",
        component: () => import("@/views/activities/edit/index.vue"),
        meta: {
          pageTitle: "Edit Activity",
          breadcrumbs: ["Edit Activity"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
          ],
        },
      },
      {
        path: "/activities/view/:id",
        name: "activities-view",
        component: () => import("@/views/activities/view/index.vue"),
        meta: {
          pageTitle: "Activity View",
          breadcrumbs: ["Activity View"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
          ],
        },
      },
      {
        path: "/quotes",
        name: "quotes",
        component: () => import("@/views/quotes/index.vue"),
        meta: {
          pageTitle: "Quotes",
          breadcrumbs: ["Quotes"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
          ],
        },
      },
      {
        path: "/quotes/add",
        name: "quotes-add",
        component: () => import("@/views/quotes/add/index.vue"),
        meta: {
          pageTitle: "Add Quote",
          breadcrumbs: ["Add Quote"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
          ],
        },
      },
      {
        path: "/quotes/edit/:id",
        name: "quotes-edit",
        component: () => import("@/views/quotes/edit/index.vue"),
        meta: {
          pageTitle: "Edit Quote",
          breadcrumbs: ["Edit Quote"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
          ],
        },
      },
      {
        path: "/quotes/view/:id",
        name: "quotes-view",
        component: () => import("@/views/quotes/view/index.vue"),
        meta: {
          pageTitle: "Quote View",
          breadcrumbs: ["Quote View"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
          ],
        },
      },
      {
        path: "/projects",
        name: "projects",
        component: () => import("@/views/projects/index.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumbs: ["Projects"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
          ],
        },
      },
      {
        path: "/projects/add",
        name: "projects-add",
        component: () => import("@/views/projects/add/index.vue"),
        meta: {
          pageTitle: "Add Project",
          breadcrumbs: ["Add Project"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
          ],
        },
      },
      {
        path: "/projects/edit/:id",
        name: "projects-edit",
        component: () => import("@/views/projects/edit/index.vue"),
        meta: {
          pageTitle: "Edit Project",
          breadcrumbs: ["Edit Project"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
          ],
        },
      },
      {
        path: "/projects/view/:id",
        name: "projects-view",
        component: () => import("@/views/projects/view/index.vue"),
        meta: {
          pageTitle: "Project View",
          breadcrumbs: ["Project View"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
          ],
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/users/index.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: ["Users"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/users/add",
        name: "users-add",
        component: () => import("@/views/users/add/index.vue"),
        meta: {
          pageTitle: "Add User",
          breadcrumbs: ["Add User"],
          requiresAuth: true,
          permissions: [
            "ROLE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_HR_USER",
          ],
        },
      },

      {
        path: "/users/edit/:id",
        name: "user-edit",
        component: () => import("@/views/users/edit/index.vue"),
        meta: {
          pageTitle: "Edit User",
          breadcrumbs: ["Edit User"],
          requiresAuth: true,
          permissions: [
            "ROLE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_HR_USER",
          ],
        },
      },
      {
        path: "/users/profile/:id",
        name: "user-profile",
        component: () => import("@/views/users/view/index.vue"),
        meta: {
          pageTitle: "User Profile",
          breadcrumbs: ["User Profile"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("@/views/customers/index.vue"),
        meta: {
          pageTitle: "Customers",
          breadcrumbs: ["Customers"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/logo-customers",
        name: "logo-customers",
        component: () => import("@/views/logo-customers/index.vue"),
        meta: {
          pageTitle: "Logo Customers",
          breadcrumbs: ["Logo Customers"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/logo-customers/edit/:id",
        name: "logo-customer-edit",
        component: () => import("@/views/logo-customers/edit/index.vue"),
        meta: {
          pageTitle: "Logo Customers",
          breadcrumbs: ["Logo Customers"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/logo-customers/view/:id",
        name: "logo-customer-view",
        component: () => import("@/views/logo-customers/view/index.vue"),
        meta: {
          pageTitle: "Logo Customers",
          breadcrumbs: ["Logo Customers"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/lines",
        name: "lines",
        component: () => import("@/views/lines/index.vue"),
        meta: {
          pageTitle: "Lines",
          breadcrumbs: ["Lines"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/expo",
        name: "expo",
        component: () => import("@/views/expo/index.vue"),
        meta: {
          pageTitle: "Expo",
          breadcrumbs: ["Expo"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
            "ROLE_SECRETARY",
          ],
        },
      },
      {
        path: "/expo/add",
        name: "expo-add",
        component: () => import("@/views/expo/add/index.vue"),
        meta: {
          pageTitle: "Add Expo",
          breadcrumbs: ["Add Expo"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
          ],
        },
      },
      {
        path: "/expo/:parent_id/plans",
        name: "expo-plans",
        component: () => import("@/views/expo/plans/index.vue"),
        meta: {
          pageTitle: "Expo Plans",
          breadcrumbs: ["Expo Plans"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
          ],
        },
      },
      {
        path: "/expo/:parent_id/reports",
        name: "expo-reports-list",
        component: () =>
          import("@/views/expo/components/ExpoReportListPage.vue"),
        meta: {
          pageTitle: "Expo Reports",
          breadcrumbs: ["Expo Reports"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
          ],
        },
      },
      {
        path: "/expo/:parent_id/reports/update/:id",
        name: "create-expo-report-page",
        component: () => import("@/views/expo/reports/index.vue"),
        meta: {
          pageTitle: "Expo Update",
          breadcrumbs: ["Expo Update"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
          ],
        },
      },
      {
        path: "/expo/:parent_id/media",
        name: "expo-media-list-page",
        component: () =>
          import("@/views/expo/components/ExpoMediaListPage.vue"),
        meta: {
          pageTitle: "Expo Media",
          breadcrumbs: ["Expo Media"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
            "ROLE_SECRETARY",
          ],
        },
      },
      {
        path: "/samples",
        name: "samples",
        component: () => import("@/views/samples/index.vue"),
        meta: {
          pageTitle: "Samples",
          breadcrumbs: ["Samples"],
          requiresAuth: true,
          permissions: [
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_FAE",
            "ROLE_FINANCE_USER_RSP_SAMPLE",
            "ROLE_SB_STORAGE_USER",
            "ROLE_ELK_STORAGE_USER",
            "ROLE_SECRETARY",
            "ROLE_FINANCE_USER",
            "ROLE_READ_ONLY",
            "ROLE_CUSTOMER_REPRESENTATIVE",
            "ROLE_SHIPMENT_USER",
            "ROLE_SUPER",
            "ROLE_LINE_MANAGER",
            "ROLE_MANAGER",
          ],
        },
      },
      {
        path: "/samples/add",
        name: "samples-add",
        component: () => import("@/views/samples/add/index.vue"),
        meta: {
          pageTitle: "Add Sample",
          breadcrumbs: ["Add Sample"],
          requiresAuth: true,
          permissions: [
            "ROLE_MANAGER",
            "ROLE_FAE",
            "ROLE_LINE_MANAGER",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_SUPER",
          ],
        },
      },
      {
        path: "/samples/edit/:id",
        name: "samples-edit",
        component: () => import("@/views/samples/edit/index.vue"),
        meta: {
          pageTitle: "Sample Edit",
          breadcrumbs: ["Sample Edit"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_TOP_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_CUSTOMER_REPRESENTATIVE",
            "ROLE_FINANCE_USER",
            "ROLE_SHIPMENT_USER",
          ],
        },
      },
      {
        path: "/samples/view/:id",
        name: "samples-view",
        component: () => import("@/views/samples/view/index.vue"),
        meta: {
          pageTitle: "Sample View",
          breadcrumbs: ["Sample View"],
          requiresAuth: true,
          permissions: [
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_FAE",
            "ROLE_FINANCE_USER_RSP_SAMPLE",
            "ROLE_SB_STORAGE_USER",
            "ROLE_ELK_STORAGE_USER",
            "ROLE_SECRETARY",
            "ROLE_FINANCE_USER",
            "ROLE_READ_ONLY",
            "ROLE_CUSTOMER_REPRESENTATIVE",
            "ROLE_SHIPMENT_USER",
            "ROLE_SUPER",
            "ROLE_LINE_MANAGER",
            "ROLE_MANAGER",
          ],
        },
      },
      {
        path: "/sync/customer",
        name: "customer-sync",
        component: () => import("@/views/sync/customer/index.vue"),
        meta: {
          pageTitle: "Customer Sync",
          breadcrumbs: ["Customer Sync"],
          requiresAuth: true,
          permissions: ["ROLE_MANAGER", "ROLE_SUPER"],
        },
      },
      {
        path: "/sync/product",
        name: "product-sync",
        component: () => import("@/views/sync/product/index.vue"),
        meta: {
          pageTitle: "Product Sync",
          breadcrumbs: ["Product Sync"],
          requiresAuth: true,
          permissions: ["ROLE_MANAGER", "ROLE_SUPER"],
        },
      },
      {
        path: "/logistics/free-zone-operations",
        name: "free-zone-operations",
        component: () => import("@/views/logistics/free-zone/index.vue"),
        meta: {
          pageTitle: "Freezone Operations",
          breadcrumbs: ["Freezone Operations"],
          requiresAuth: true,
          permissions: ["ROLE_SUPER", "ROLE_SHIPMENT_USER", "ROLE_TOP_MANAGER"],
        },
      },
      {
        path: "/logistics/purchase-orders",
        name: "purchase-orders",
        component: () => import("@/views/logistics/purchase-orders/index.vue"),
        meta: {
          pageTitle: "Purchase Orders",
          breadcrumbs: ["Purchase Orders"],
          requiresAuth: true,
          permissions: ["ROLE_SUPER", "ROLE_SHIPMENT_USER", "ROLE_TOP_MANAGER"],
        },
      },
      {
        path: "/logistics/purchase-orders/:id",
        name: "purchase-orders-view",
        component: () =>
          import("@/views/logistics/purchase-orders/view/index.vue"),
        meta: {
          pageTitle: "Purchase Orders View",
          breadcrumbs: ["Purchase Orders View"],
          requiresAuth: true,
          permissions: ["ROLE_SUPER", "ROLE_SHIPMENT_USER", "ROLE_TOP_MANAGER"],
        },
      },
      {
        path: "/logistics/purchase-orders/:id/items/:itemId",
        name: "purchase-orders-items-view",
        component: () =>
          import("@/views/logistics/purchase-orders/items/index.vue"),
        meta: {
          pageTitle: "Purchase Orders Item View",
          breadcrumbs: ["Purchase Orders Item View"],
          requiresAuth: true,
          permissions: ["ROLE_SUPER", "ROLE_SHIPMENT_USER", "ROLE_TOP_MANAGER"],
        },
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/views/calendar/index.vue"),
        meta: {
          pageTitle: "Calendar",
          breadcrumbs: ["Calendar"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/iot",
        name: "IoT",
        component: () => import("@/views/iot/index.vue"),
        meta: {
          pageTitle: "IoT",
          breadcrumbs: ["IoT"],
          requiresAuth: true,
          permissions: ["ROLE_SUPER", "ROLE_IT_USER"],
        },
      },
      {
        path: "/tickets",
        name: "tickets",
        component: () => import("@/views/tickets/index.vue"),
        meta: {
          pageTitle: "Tickets",
          breadcrumbs: ["Tickets"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/customer-support",
        name: "technology-supports-view",
        component: () => import("@/views/customer-support/index.vue"),
        meta: {
          pageTitle: "Technology Supports",
          breadcrumbs: ["Technology Supports"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/tickets/:id",
        name: "tickets-view",
        component: () => import("@/views/tickets/view/index.vue"),
        meta: {
          pageTitle: "Tickets Details",
          breadcrumbs: ["Tickets Details"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/notification/index.vue"),
        meta: {
          pageTitle: "Notifications",
          breadcrumbs: ["Notifications"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/reports/ACTIVITY-ADMIN-UNCOMPLETED-REPORT/",
        name: "activity-admin-uncompleted-report",
        component: () => import("@/views/reports/activity/index.vue"),
        meta: {
          pageTitle: "Activity Admin Reports Data",
          breadcrumbs: ["Activity Admin Reports Data"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "repots/MARKETING-PERFORMANCE-REPORT/",
        name: "marketing-performancereport",
        component: () => import("@/views/reports/performance/index.vue"),
        meta: {
          pageTitle: "Marketing Performance Report",
          breadcrumbs: ["Marketing Performance Report"],
          requiresAuth: true,
          permissions: ["ROLE_SUPER", "ROLE_MANAGER"],
        },
      },
      {
        path: "/annual-target",
        name: "annual-target",
        component: () => import("@/views/targets/index.vue"),
        meta: {
          pageTitle: "Annual Target",
          breadcrumbs: ["Annual Target"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/annual-target/edit/:id",
        name: "update-annual-target",
        component: () => import("@/views/targets/edit/index.vue"),
        meta: {
          pageTitle: "Update Annual Target",
          breadcrumbs: ["Update Annual Target"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_IT_USER",
          ],
        },
      },
      {
        path: "/approvals",
        name: "approvals",
        component: () => import("@/views/approvals/index.vue"),
        meta: {
          pageTitle: "Approvals",
          breadcrumbs: ["Approvals"],
          requiresAuth: true,
          permissions: ["ROLE_MANAGER", "ROLE_SUPER"],
        },
      },
      {
        path: "/kpi-management",
        name: "kpi-management",
        component: () => import("@/views/kpi-management/index.vue"),
        meta: {
          pageTitle: "Kpi Management",
          breadcrumbs: ["Kpi Management"],
          requiresAuth: true,
          permissions: ["ROLE_MANAGER", "ROLE_SUPER"],
        },
      },
      {
        path: "/kpi-marketing-management",
        name: "kpi-marketing-management",
        component: () => import("@/views/kpi-marketing-management/index.vue"),
        meta: {
          pageTitle: "Marketing Team Management",
          breadcrumbs: ["Marketing Team Management"],
          requiresAuth: true,
          permissions: ["ROLE_MANAGER", "ROLE_SUPER"],
        },
      },
      {
        path: "/business-card/:name",
        name: "business-card",
        component: () => import("@/views/business-card/index.vue"),
        meta: {
          pageTitle: "Business Card",
          breadcrumbs: ["Business Card"],
          requiresAuth: !!JwtService.getToken(),
        },
      },
      {
        path: "/changelog",
        name: "changelog",
        component: () => import("@/views/changelog/index.vue"),
        meta: {
          pageTitle: "Changelog",
          breadcrumbs: ["Changelog"],
          requiresAuth: true,
          permissions: userRoles,
        },
      },
      {
        path: "/purchase-orders",
        name: "purchase-orders",
        component: () => import("@/views/pof/index.vue"),
        meta: {
          pageTitle: "Purchase Order Form",
          breadcrumbs: ["Purchase Order Form"],
          requiresAuth: true,
          permissions: [
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_FAE",
            "ROLE_FINANCE_USER_RSP_SAMPLE",
            "ROLE_SB_STORAGE_USER",
            "ROLE_ELK_STORAGE_USER",
            "ROLE_SECRETARY",
            "ROLE_FINANCE_USER",
            "ROLE_READ_ONLY",
            "ROLE_CUSTOMER_REPRESENTATIVE",
            "ROLE_SHIPMENT_USER",
            "ROLE_SUPER",
            "ROLE_LINE_MANAGER",
            "ROLE_MANAGER",
          ],
        },
      },
      {
        path: "/purchase-orders/add",
        name: "purchase-order-add",
        component: () => import("@/views/pof/add/index.vue"),
        meta: {
          pageTitle: "Add Purchase Order Form",
          breadcrumbs: ["Add Purchase Order Form"],
          requiresAuth: true,
          permissions: [
            "ROLE_MANAGER",
            "ROLE_FAE",
            "ROLE_LINE_MANAGER",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_SUPER",
          ],
        },
      },
      {
        path: "/purchase-orders/edit/:id",
        name: "purchase-order-edit",
        component: () => import("@/views/pof/edit/index.vue"),
        meta: {
          pageTitle: "Edit Purchase Order Form",
          breadcrumbs: ["Edit Purchase Order Form"],
          requiresAuth: true,
          permissions: [
            "ROLE_MANAGER",
            "ROLE_FAE",
            "ROLE_LINE_MANAGER",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_SUPER",
          ],
        },
      },
      {
        path: "/purchase-orders/view/:id",
        name: "purchase-orders-view",
        component: () => import("@/views/pof/view/index.vue"),
        meta: {
          pageTitle: "Purchase Order Form View",
          breadcrumbs: ["Purchase Order Form View"],
          requiresAuth: true,
          permissions: [
            "ROLE_FAE",
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_MANAGER",
            "ROLE_LINE_MANAGER",
            "ROLE_SUPER",
            "ROLE_TOP_MANAGER",
            "ROLE_READ_ONLY",
          ],
        },
      },
      {
        path: "/inventory",
        name: "inventory",
        component: () => import("@/views/inventory/index.vue"),
        meta: {
          pageTitle: "Inventory Information",
          breadcrumbs: ["Inventory Information"],
          requiresAuth: true,
          permissions: [
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_FAE",
            "ROLE_FINANCE_USER_RSP_SAMPLE",
            "ROLE_SB_STORAGE_USER",
            "ROLE_ELK_STORAGE_USER",
            "ROLE_SECRETARY",
            "ROLE_FINANCE_USER",
            "ROLE_READ_ONLY",
            "ROLE_CUSTOMER_REPRESENTATIVE",
            "ROLE_SHIPMENT_USER",
            "ROLE_SUPER",
            "ROLE_LINE_MANAGER",
            "ROLE_MANAGER",
          ],
        },
      },
      {
        path: "/special-prices/add",
        name: "special-price-add",
        component: () =>
          import("@/views/inventory/special-prices/add/index.vue"),
        meta: {
          pageTitle: "Add Special Price",
          breadcrumbs: ["Add Special Price"],
          requiresAuth: true,
          permissions: [
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_FAE",
            "ROLE_FINANCE_USER_RSP_SAMPLE",
            "ROLE_SB_STORAGE_USER",
            "ROLE_ELK_STORAGE_USER",
            "ROLE_SECRETARY",
            "ROLE_FINANCE_USER",
            "ROLE_READ_ONLY",
            "ROLE_CUSTOMER_REPRESENTATIVE",
            "ROLE_SHIPMENT_USER",
            "ROLE_SUPER",
            "ROLE_LINE_MANAGER",
            "ROLE_MANAGER",
          ],
        },
      },
      {
        path: "/special-prices/edit/:id",
        name: "special-prices-edit",
        component: () =>
          import("@/views/inventory/special-prices/edit/index.vue"),
        meta: {
          pageTitle: "Edit Special Price",
          breadcrumbs: ["Edit Special Price"],
          requiresAuth: true,
          permissions: [
            "ROLE_ACCOUNT_MANAGER",
            "ROLE_FAE",
            "ROLE_FINANCE_USER_RSP_SAMPLE",
            "ROLE_SB_STORAGE_USER",
            "ROLE_ELK_STORAGE_USER",
            "ROLE_SECRETARY",
            "ROLE_FINANCE_USER",
            "ROLE_READ_ONLY",
            "ROLE_CUSTOMER_REPRESENTATIVE",
            "ROLE_SHIPMENT_USER",
            "ROLE_SUPER",
            "ROLE_LINE_MANAGER",
            "ROLE_MANAGER",
          ],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/authentication/ForgotPassword.vue"),
        meta: {
          pageTitle: "Forgot Password",
          breadcrumbs: ["Forgot Password"],
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/authentication/PasswordReset.vue"),
        meta: {
          pageTitle: "Password Reset",
          breadcrumbs: ["Password Reset"],
        },
        props: (route) => ({ token: route.query.token }),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SimpleLayout.vue"),
    children: [
      {
        path: "/logistics/purchase-orders/:id/items/:itemId/track/:trackId",
        name: "purchase-orders-items-track",
        meta: {
          pageTitle: "Purchase Orders Items Track",
          requiresAuth: true,
          permissions: ["ROLE_SUPER", "ROLE_SHIPMENT_USER", "ROLE_TOP_MANAGER"],
        },
        component: () =>
          import(
            "@/views/logistics/purchase-orders/items/PurchaseOrderItemTrack.vue"
          ),
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
          requiresAuth: !!JwtService.getToken(),
        },
      },
      {
        path: "/maintenance",
        name: "under-maintenance",
        component: () => import("@/views/authentication/UnderMaintenance.vue"),
        meta: {
          pageTitle: "Under Maintenance",
          requiresAuth: !!JwtService.getToken(),
        },
      },
    ],
  },
  {
    // File Download Page
    path: "/shared/:url_token",
    name: "storage-file-download",
    meta: {
      pageTitle: "Download File",
      requiresAuth: !!JwtService.getToken(),
    },
    component: () => import("@/views/storage/download/index.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
    meta: {
      requiresAuth: !!JwtService.getToken(),
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const utilityStore = useUtilityStore();
  const configStore = useConfigStore();
  const pofStore = usePofStore();
  let isPermission = false;

  // current page view title
  document.title = `${import.meta.env.VITE_APP_NAME} - ${to.meta.pageTitle}`;

  if (JwtService.getToken()) {
    utilityStore.getAppInfo();
  }

  if (!to.path.includes("quotes")) {
    QuoteFilterService.destroyQuoteFilter();
    window.sessionStorage.removeItem("is_create_pof");
    pofStore.resetPofProducts();
  }

  if (!to.path.includes("activities")) {
    ActivityFilterService.destroyActivityFilter();
  }

  if (!to.path.includes("projects")) {
    ProjectFilterService.destroyProjectFilter();
  }

  if (!to.path.includes("purchase-orders")) {
    PurchaseOrderFormService.destroyPurchaseOrderFormFilter();
  }
  
  if (!to.path.includes("inventory") && !to.path.includes("special-prices")) {
    SpecialPricesFilterService.destroySpecialPricesFilter();
  }

  // reset config to initial state
  configStore.resetLayoutConfig();
  const user = UserService.getUserInfo();

  if (user && to.matched.some((record) => record.meta.permissions)) {
    user["roles"].map((role, index) => {
      const permissions = to.meta.permissions as [];
      if (permissions.includes(role["name"] as never)) {
        isPermission = true;
      }

      if (index + 1 === user["roles"].length) {
        if (!isPermission) {
          return next("/404");
        }
      }
    });
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !JwtService.getToken()
  ) {
    return next("/sign-in");
  } else if (
    !to.matched.some((record) => record.meta.requiresAuth) &&
    JwtService.getToken()
  ) {
    return next("/");
  }

  next();

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;

import { ref, computed } from "vue";
import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import FirebaseService from "@/core/services/FirebaseService";
import router from "../router";
import { loginAnalytics, setAnalyticProperties } from "@/firebase";

export interface User {
  first_name?: string;
  last_name?: string;
  email: string;
  password: string;
  api_token?: string;
  collages_bday?: [];
  force_2fa_until?: string;
  id?: number;
  is_my_bday?: boolean;
  menu_items?: [];
  otp?: string | null;
  phone_number?: string;
  roles?: { id?: number; name?: string }[];
  username?: string;
  photo?: string;
  abbr_code?: string;
}

export interface ILoginCredentials {
  username: string;
}

export interface ILoginOtpCredentials {
  code: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

export const useAuthStore = defineStore("auth", () => {
  const errors = ref({});
  const user = ref<User>({} as User);
  const isAuthenticated = ref(!!JwtService.getToken());

  const currentUser = computed(() => {
    return user.value;
  });

  function isUserAuthenticated() {
    return isAuthenticated.value;
  }

  function setToken(token: string) {
    isAuthenticated.value = true;
    JwtService.saveToken(token);
    ApiService.setHeader();
  }

  function setAuth(authUser) {
    try {
      loginAnalytics(authUser.id);

      setAnalyticProperties(
        authUser.roles.map((item) => item.name),
        authUser.address,
        authUser.department.name,
        authUser.lines.map((item) => item.code)
      );
    } catch (e) {
      console.log("Analytics Error");
    }

    user.value = authUser;
    UserService.saveUserInfo(authUser);
  }

  function purgeAuth() {
    isAuthenticated.value = false;
    user.value = {} as User;
    errors.value = [];
    JwtService.destroyToken();
    UserService.destroyUserInfo();
    FirebaseService.destroyFirebaseToken();
    localStorage.removeItem("old_token");
    localStorage.removeItem("is_switch_user");
  }

  async function login(credentials: ILoginCredentials) {
    try {
      const response = await ApiService.post(
        "/api/user/auth/otp-request/",
        credentials
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function loginOtp(credentials: ILoginOtpCredentials) {
    try {
      const response = await ApiService.post(
        "/api/user/auth/otp-verification/",
        credentials,
        {
          skipAuthRefresh: true,
        }
      );
      setToken(response.data.token.access_token);
      JwtService.refreshToken(response.data.token.refresh_token);
      await userMe();
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function userMe() {
    try {
      const response = await ApiService.get("/api/user/me/");
      setAuth(response.data);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function forgotPassword(data) {
    try {
      const response = await ApiService.post(`/api/user/password-reset/`, data);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function passwordResetVerifyToken(token) {
    try {
      const response = await ApiService.get(
        `/api/user/password-reset/verify/${token}/`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function passwordReset(payload) {
    try {
      const response = await ApiService.post(
        `/api/user/password-reset/change/${payload.token}/`,
        payload.data
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function editUpdatePassword(payload) {
    try {
      const response = await ApiService.put(
        `/api/user/password-change/${payload.id}/`,
        payload.data
      );
      setAuth(response.data);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function userTokenBlacklist(token) {
    try {
      const response = await ApiService.post(
        "/api/user/token/blacklist/",
        token,
        { skipAuthRefresh: true }
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  function logout() {
    if (JwtService.getRefreshToken()) {
      userTokenBlacklist({
        refresh: JwtService.getRefreshToken(),
      });
    }
    purgeAuth();
  }

  async function verifyAuth(payload) {
    try {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        const response = await ApiService.post(
          "/api/user/token/refresh/",
          payload,
          { skipAuthRefresh: true }
        );
        setToken(response.data.access);
        JwtService.refreshToken(response.data.refresh);
        ApiService.setHeader();
        return Promise.resolve(response.data);
      } else {
        purgeAuth();
      }
    } catch (error) {
      purgeAuth();
    }
  }

  return {
    errors,
    user,
    isAuthenticated,
    currentUser,
    isUserAuthenticated,
    login,
    userMe,
    logout,
    verifyAuth,
    editUpdatePassword,
    passwordReset,
    forgotPassword,
    passwordResetVerifyToken,
    loginOtp,
  };
});
